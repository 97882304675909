import React from "react";
import ImageWithModal from "../../../common/ImageWithModal";
import createWorkspaceImg from "../../../../images/docs/using/create-workspace.png";
import workspaceChatsViewImg from "../../../../images/docs/using/workspace-chat-view.png";
import workspaceChatThreadsViewImg from "../../../../images/docs/using/workspace-chat-threads-view.png";
import threadStartNewImg from "../../../../images/docs/using/thread-start-new.png";
import { Link } from "react-router-dom";

const WorkspacesChatsThreads = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">Workspaces, Chats, & Threads</h1>

			<p className="mb-4">
				Protocraft organizes your work into a hierarchical structure of Workspaces, Chats, and Threads. This organization helps you manage different
				projects, topics, and conversations efficiently.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Workspaces</h2>
			<p className="mb-4">
				Workspaces are the top-level organizational units in Protocraft. They allow you to separate different projects or areas of work.
			</p>

			<h3 className="text-xl font-semibold mt-6 mb-3">Creating a Workspace</h3>
			<ImageWithModal src={createWorkspaceImg} alt="Create Workspace" className="mb-4" />
			<p className="mb-4">To create a new workspace:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Click on the "+" button next to the workspace dropdown at the top of the sidebar.</li>
				<li>Enter a name for your new workspace in the popup dialog.</li>
				<li>Click "Create" to create the new workspace.</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Chats</h2>
			<p className="mb-4">Chats are conversations within a workspace. They help you organize different topics or sessions within a project.</p>

			<h3 className="text-xl font-semibold mt-6 mb-3">Viewing Chats</h3>
			<ImageWithModal src={workspaceChatsViewImg} alt="Workspace Chats View" className="mb-4" />
			<p className="mb-4">To view chats in a workspace:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Select the desired workspace from the dropdown at the top of the sidebar.</li>
				<li>The chats for the selected workspace will be displayed in the sidebar and under the Workspace tab of the program.</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Threads</h2>
			<p className="mb-4">
				Threads are individual conversation sessions within a chat. They allow you to have multiple related conversations under the same chat topic.
			</p>

			<h3 className="text-xl font-semibold mt-6 mb-3">Viewing Threads</h3>
			<ImageWithModal src={workspaceChatThreadsViewImg} alt="Workspace Chat Threads View" className="mb-4" />
			<p className="mb-4">To view threads in a chat:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Select the desired chat from the sidebar.</li>
				<li>The threads for the selected chat will be displayed in the main content area.</li>
			</ol>

			<h3 className="text-xl font-semibold mt-6 mb-3">Creating a New Thread</h3>
			<ImageWithModal src={threadStartNewImg} alt="Start New Thread" className="mb-4" />
			<p className="mb-4">To create a new thread:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Click on the "Start New Thread" button at the top of the chat interface.</li>
				<li>Enter your initial message or prompt for the new thread.</li>
				<li>Press Enter or click the Send button to start the new thread.</li>
			</ol>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Benefits of Workspaces, Chats, and Threads</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>
					<strong>Organization:</strong> Easily manage multiple projects and topics.
				</li>
				<li>
					<strong>Context Preservation:</strong> Keep related conversations together for better context and continuity.
				</li>
				<li>
					<strong>Flexibility:</strong> Switch between different projects and conversations effortlessly.
				</li>
			</ul>

			<p className="mt-4">
				By effectively using Workspaces, Chats, and Threads, you can streamline your workflow and make the most of Protocraft's organizational features.
				For more information on how to interact with the AI within these structures, see the{" "}
				<Link to="/docs/prompting-with-chat" className="text-blue-500 hover:underline">
					Prompting with Chat
				</Link>{" "}
				section.
			</p>
		</div>
	);
};

export default WorkspacesChatsThreads;
