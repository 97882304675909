import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Introduction from "./introduction/Introduction";
import HowToDownload from "./introduction/HowToDownload";
import LicensingAndPricing from "./introduction/LicensingAndPricing";
import StartingUpProtocraft from "./setup/StartingUpProtocraft";
import SettingUpAPIKeys from "./setup/SettingUpAPIKeys";
import SettingUpLocalLLM from "./setup/SettingUpLocalLLM";
import SettingUpGoogleSearch from "./setup/SettingUpGoogleSearch";
import AlwaysIgnoreFiles from "./setup/AlwaysIgnoreFiles";
import DataManagement from "./setup/DataManagement";
import PromptingWithChat from "./using/PromptingWithChat";
import ChoosingLLMs from "./using/ChoosingLLMs";
import UsingTemplates from "./using/UsingTemplates";
import SettingInitializationRules from "./using/SettingInitializationRules";
import UsingTools from "./using/UsingTools";
import UsingPromptImageVision from "./using/UsingPromptImageVision";
import WorkspacesChatsThreads from "./using/WorkspacesChatsThreads";
import ProjectIgnoreRules from "./using/ProjectIgnoreRules";
import FileBrowserOverview from "./file-browser/FileBrowserOverview";
import UsingFiles from "./file-browser/UsingFiles";
import FileViewerOptions from "./file-browser/FileViewerOptions";
import EditToolAndFileDiffs from "./file-browser/EditToolAndFileDiffs";
import UsingPreviewScreenshots from "./file-browser/UsingPreviewScreenshots";
import IncludingFilesInPrompts from "./file-browser/IncludingFilesInPrompts";

const Documentation = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	const topics = [
		{
			name: "Introduction",
			pages: [
				{ name: "What is Protocraft?", path: "/docs/introduction" },
				{ name: "How to Download", path: "/docs/how-to-download" },
				{ name: "Licensing and Pricing", path: "/docs/licensing-and-pricing" },
			],
		},
		{
			name: "Setup",
			pages: [
				{ name: "Starting up Protocraft", path: "/docs/starting-up-protocraft" },
				{ name: "Setting Up API Keys", path: "/docs/setting-up-api-keys" },
				{ name: "Setting Up Local LLM", path: "/docs/setting-up-local-llm" },
				{ name: "Setting Up Google Search", path: "/docs/setting-up-google-search" },
				{ name: "Always Ignore Files", path: "/docs/always-ignore-files" },
				{ name: "Data Management", path: "/docs/data-management" },
			],
		},
		{
			name: "Using Protocraft",
			pages: [
				{ name: "Workspaces, Chats, & Threads", path: "/docs/workspaces-chats-threads" },
				{ name: "Prompting with Chat", path: "/docs/prompting-with-chat" },
				{ name: "Choosing LLMs", path: "/docs/choosing-llms" },
				{ name: "Using Templates", path: "/docs/using-templates" },
				{ name: "Setting Initialization Rules", path: "/docs/setting-initialization-rules" },
				{ name: "Using Tools", path: "/docs/using-tools" },
				{ name: "Using Prompt Image Vision", path: "/docs/using-prompt-image-vision" },
				{ name: "Project Ignore Rules", path: "/docs/project-ignore-rules" },
			],
		},
		{
			name: "File Browser",
			pages: [
				{ name: "File Browser Overview", path: "/docs/file-browser-overview" },
				{ name: "Using Files", path: "/docs/using-files" },
				{ name: "File Viewer Options", path: "/docs/file-viewer-options" },
				{ name: "Including Files In Prompts", path: "/docs/including-files-in-prompts" },
				{ name: "Edit Tool & File Diffs", path: "/docs/edit-tool-and-file-diffs" },
				{ name: "Using Preview Screenshots", path: "/docs/using-preview-screenshots" },
			],
		},
		// Add more topics as needed
	];

	useEffect(() => {
		if (location.pathname === "/docs" || location.pathname === "/docs/") {
			navigate(topics[0].pages[0].path);
		}
	}, [location, navigate]);

	const findCurrentPage = () => {
		for (const topic of topics) {
			const page = topic.pages.find((p) => p.path === location.pathname);
			if (page) {
				return { topic, page };
			}
		}
		return null;
	};

	const currentPage = findCurrentPage();

	const findNextAndPrevPages = () => {
		if (!currentPage) return { prev: null, next: null };

		let allPages = topics.flatMap((t) => t.pages);
		let currentIndex = allPages.findIndex((p) => p.path === currentPage.page.path);

		return {
			prev: currentIndex > 0 ? allPages[currentIndex - 1] : null,
			next: currentIndex < allPages.length - 1 ? allPages[currentIndex + 1] : null,
		};
	};

	const { prev, next } = findNextAndPrevPages();

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	return (
		<div className="container mx-auto px-4 py-8 flex flex-col md:flex-row">
			{/* Mobile sidebar toggle button */}
			<button className="md:hidden mb-4 bg-purple-600 text-white px-4 py-2 rounded" onClick={toggleSidebar}>
				{isSidebarOpen ? "Close Navigation" : "Documentation Navigation"}
			</button>

			{/* Sidebar */}
			<div className={`w-full md:w-1/4 md:pr-8 mb-8 md:mb-0 ${isSidebarOpen ? "block" : "hidden md:block"}`}>
				<h2 className="text-xl font-bold mb-4">Documentation</h2>
				<div className="grid grid-cols-2 md:grid-cols-1 gap-4">
					{topics.map((topic, index) => (
						<div key={index} className="mb-4">
							<h3 className="text-lg font-semibold mb-2">{topic.name}</h3>
							<ul className="space-y-1">
								{topic.pages.map((page, pageIndex) => (
									<li key={pageIndex}>
										<Link
											to={page.path}
											className={`text-sm hover:text-purple-600 ${location.pathname === page.path ? "text-purple-600 font-semibold" : ""}`}
											onClick={() => setIsSidebarOpen(false)}
										>
											{page.name}
										</Link>
									</li>
								))}
							</ul>
						</div>
					))}
				</div>
			</div>

			{/* Main content */}
			<div className="w-full md:w-3/4">
				<Routes>
					<Route path="/introduction" element={<Introduction />} />
					<Route path="/how-to-download" element={<HowToDownload />} />
					<Route path="/licensing-and-pricing" element={<LicensingAndPricing />} />
					<Route path="/starting-up-protocraft" element={<StartingUpProtocraft />} />
					<Route path="/setting-up-api-keys" element={<SettingUpAPIKeys />} />
					<Route path="/setting-up-local-llm" element={<SettingUpLocalLLM />} />
					<Route path="/setting-up-google-search" element={<SettingUpGoogleSearch />} />
					<Route path="/always-ignore-files" element={<AlwaysIgnoreFiles />} />
					<Route path="/data-management" element={<DataManagement />} />
					<Route path="/workspaces-chats-threads" element={<WorkspacesChatsThreads />} />
					<Route path="/prompting-with-chat" element={<PromptingWithChat />} />
					<Route path="/choosing-llms" element={<ChoosingLLMs />} />
					<Route path="/using-templates" element={<UsingTemplates />} />
					<Route path="/setting-initialization-rules" element={<SettingInitializationRules />} />
					<Route path="/using-tools" element={<UsingTools />} />
					<Route path="/using-prompt-image-vision" element={<UsingPromptImageVision />} />
					<Route path="/project-ignore-rules" element={<ProjectIgnoreRules />} />
					<Route path="/file-browser-overview" element={<FileBrowserOverview />} />
					<Route path="/using-files" element={<UsingFiles />} />
					<Route path="/file-viewer-options" element={<FileViewerOptions />} />
					<Route path="/edit-tool-and-file-diffs" element={<EditToolAndFileDiffs />} />
					<Route path="/using-preview-screenshots" element={<UsingPreviewScreenshots />} />
					<Route path="/including-files-in-prompts" element={<IncludingFilesInPrompts />} />
				</Routes>

				{/* Navigation buttons */}
				<div className="mt-8 flex justify-between">
					{prev && (
						<Link to={prev.path} className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 transition duration-200">
							← {prev.name}
						</Link>
					)}
					{next && (
						<Link to={next.path} className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 transition duration-200">
							{next.name} →
						</Link>
					)}
				</div>
			</div>
		</div>
	);
};

export default Documentation;
