import React, { useState } from "react";
import { FaEnvelope, FaLock, FaDownload, FaWindows, FaApple, FaLinux } from "react-icons/fa";
import { Link } from "react-router-dom";
import { call } from "../../services/api";

const Download = () => {
	const [selectedDownloadType, setSelectedDownloadType] = useState<string | null>(null);
	const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
	const [isDownloading, setIsDownloading] = useState(false);
	const [downloadError, setDownloadError] = useState<string | null>(null);

	const getDownloadUrl = async (identifier: string) => {
		try {
			if (downloadUrl && selectedDownloadType === identifier) {
				window.open(downloadUrl, "_blank", "noopener,noreferrer");
				return;
			}

			setIsDownloading(true);
			setSelectedDownloadType(identifier);
			setDownloadError(null);
			const response: any = await call("download/url", "POST", { identifier });
			setDownloadUrl(response.url);
			window.open(response.url, "_blank", "noopener,noreferrer");
		} catch (err: any) {
			setDownloadError(err.message || "Failed to get download URL. Please try again.");
		} finally {
			setIsDownloading(false);
		}
	};

	const DownloadButton = ({ identifier, label }: { identifier: string; label: string }) => (
		<button
			onClick={() => getDownloadUrl(identifier)}
			className={`block w-full p-3 ${
				isDownloading ? "bg-gray-400" : "bg-gradient-to-r from-purple-600 to-pink-500 hover:from-purple-700 hover:to-pink-600"
			} text-white text-center rounded-lg transition duration-200`}
			disabled={isDownloading}
		>
			{isDownloading ? "Downloading..." : label}
		</button>
	);

	return (
		<div className="container mx-auto p-6 max-w-6xl">
			<h2 className="text-4xl font-bold text-center mb-4 text-purple-600 dark:text-purple-400">Download Protocraft AI</h2>

			<p className="text-center my-4 text-gray-700 dark:text-gray-300">
				<strong>Note:</strong> Protocraft is currently in public alpha. The builds may be unstable. Report any issues to{" "}
				<a href="mailto:support@protocraft.ai" className="text-pink-500 hover:text-pink-600 underline">
					our support email
				</a>
				, or{" "}
				<a
					href="https://discord.gg/TTS9eeRmUy"
					target="_blank"
					rel="noopener noreferrer"
					aria-label="Discord"
					className="text-pink-500 hover:text-pink-600 underline"
				>
					in our Discord
				</a>
				.
			</p>

			<p className="text-left my-4 text-blue-500">
				<strong>Important:</strong> If you are upgrading from Perception (v1.1.15 or earlier), Perception is now Protocraft. This is recognized as a new
				application on all OSes / platforms, so you will need to re-configure your LLM API keys and settings, and re-enter your license key. You can
				export your existing Perception data and import it into Protocraft by using the "Export" and "Import" features demonstrated here:{" "}
				<Link to="/docs/data-management" className="text-pink-500 hover:text-pink-600 underline">
					Export and Import
				</Link>
				.
			</p>

			<div className="mx-auto bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
				<div className="w-full">
					{downloadError && (
						<div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
							<strong className="font-bold">Error: </strong>
							<span className="block sm:inline">{downloadError}</span>
						</div>
					)}
					<div className="grid grid-cols-1 md:grid-cols-3 gap-12">
						<div className="bg-purple-100 dark:bg-purple-900 p-6 rounded-lg">
							<h4 className="text-2xl font-semibold mb-4 flex items-center justify-center text-purple-600 dark:text-purple-300">
								<FaWindows className="mr-3 text-4xl" /> Windows
							</h4>
							<div className="space-y-4">
								<DownloadButton identifier="windows-all-x86_64" label="Intel x86_64" />
								<DownloadButton identifier="windows-all-arm64" label="arm64" />
							</div>
						</div>
						<div className="bg-pink-100 dark:bg-pink-900 p-6 rounded-lg">
							<h4 className="text-2xl font-semibold mb-4 flex items-center justify-center text-pink-600 dark:text-pink-300">
								<FaApple className="mr-3 text-4xl" /> Mac
							</h4>
							<div className="space-y-4">
								<DownloadButton identifier="mac-all-arm64" label="M1+ (newer Macs) (arm64)" />
								<DownloadButton identifier="mac-all-x86_64" label="Intel (older Macs) x86_64" />
							</div>
						</div>
						<div className="bg-red-100 dark:bg-red-900 p-6 rounded-lg">
							<h4 className="text-2xl font-semibold mb-4 flex items-center justify-center text-red-600 dark:text-red-300">
								<FaLinux className="mr-3 text-4xl" /> Linux
							</h4>
							<div className="space-y-4">
								<h5 className="font-semibold mt-2 text-center text-gray-700 dark:text-gray-300">.deb</h5>
								<DownloadButton identifier="linux-deb-amd64" label="amd64" />
								<DownloadButton identifier="linux-deb-arm64" label="arm64" />
								<h5 className="font-semibold mt-4 text-center text-gray-700 dark:text-gray-300">.rpm</h5>
								<DownloadButton identifier="linux-rpm-x86_64" label="Intel x86_64" />
								<DownloadButton identifier="linux-rpm-arm64" label="arm64" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="mt-8 text-center">
				<Link to="/" className="text-purple-600 hover:text-purple-800 transition duration-200">
					← Back to Home
				</Link>
			</div>
		</div>
	);
};

export default Download;
