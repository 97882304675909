import React, { useState } from "react";
import workWithFilesImg from "../../images/home/work-with-files.png";
import iterateDevImg from "../../images/home/iterate-dev.png";
import { Link } from "react-router-dom";

const Home = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState("");

	const sections = [
		{
			title: "Work Directly with your Files",
			content: "Include multiple files in your prompts, ask questions about them, or ask for full edits and revisions to your documents and code.",
			image: workWithFilesImg,
		},
		{
			title: "Speed up Development & Data Analysis",
			content:
				"Create, update, and analyze your code and files with your preferred AI provider. Iterate quickly, and spend more time thinking about solutions than writing code.",
			image: iterateDevImg,
		},
	];

	const openModal = (image) => {
		setSelectedImage(image);
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
		setSelectedImage("");
	};

	return (
		<>
			<div className="bg-gradient-to-r from-purple-600 via-pink-500 to-red-500 text-white">
				<div className="container mx-auto px-4 py-16">
					<div className="flex flex-col md:flex-row items-center">
						<div className="md:w-2/5 mb-8 md:mb-0">
							<h1 className="text-2xl md:text-6xl font-bold mb-4">Personal Desktop Chat Agent</h1>
							<p className="text-lg md:text-xl mb-8 lg:pr-24">Enhance your productivity with AI-powered assistance right on your desktop.</p>
							<div className="flex flex-wrap gap-4">
								<Link to="/downloads">
									<button className="px-6 py-3 bg-white text-purple-600 text-lg font-semibold rounded-lg hover:bg-gray-100 transition duration-200">
										Download Now
									</button>
								</Link>
								<Link to="/purchase">
									<button className="px-6 py-3 bg-purple-700 text-white text-lg font-semibold rounded-lg hover:bg-purple-800 transition duration-200">
										Buy License
									</button>
								</Link>
								<Link to="/docs">
									<button className="px-6 py-3 bg-purple-500 text-white text-lg font-semibold rounded-lg hover:bg-purple-600 transition duration-200">
										Read Docs
									</button>
								</Link>
							</div>
						</div>
						<div className="md:w-3/5">
							<div className="rounded-lg overflow-hidden shadow-2xl">
								<video
									src="https://protocraft-ai-public.s3.amazonaws.com/home-demo-3_optimized.mp4"
									autoPlay
									loop
									muted
									playsInline
									className="w-full h-auto"
								>
									Your browser does not support the video tag.
								</video>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container mx-auto md:p-6 bg-gray-100 dark:bg-gray-900">
				<div className="p-8 rounded-lg text-center flex flex-col gap-1">
					<h2 className="text-3xl md:text-4xl font-bold text-center text-purple-600 dark:text-purple-400 mb-2">What is Protocraft AI?</h2>
					<p className="text-xl text-center text-gray-700 dark:text-gray-300 md:w-1/2 mx-auto">
						Desktop AI chat agent built for software development & prompt automation.
					</p>
					<p className="text-xl text-center text-gray-700 dark:text-gray-300 md:w-1/2 mx-auto mb-4">
						Use your own LLMs & API Keys, have full control over the process.
					</p>
					<div className="flex flex-wrap justify-center gap-4">
						<Link to="/downloads">
							<button className="px-6 py-3 bg-purple-600 text-white text-lg font-semibold rounded-lg hover:bg-purple-700 transition duration-200">
								Download
							</button>
						</Link>
						<Link to="/purchase">
							<button className="px-6 py-3 bg-pink-500 text-white text-lg font-semibold rounded-lg hover:bg-pink-600 transition duration-200">
								Buy License
							</button>
						</Link>
						<Link to="/docs">
							<button className="px-6 py-3 bg-purple-500 text-white text-lg font-semibold rounded-lg hover:bg-purple-600 transition duration-200">
								Read Docs
							</button>
						</Link>
					</div>
				</div>
				<div className="flex flex-col items-center justify-center mb-4">
					<a href="https://theresanaiforthat.com/ai/perception-ai/?ref=featured&v=2163546" target="_blank" rel="nofollow">
						<img width="300" src="https://media.theresanaiforthat.com/featured-on-taaft.png?width=600" />
					</a>
				</div>
				{sections.map((section, index) => (
					<div key={index} className={`flex items-center mb-4 ${index % 2 === 0 ? "flex-row" : "flex-row-reverse"} max-md:flex-col`}>
						<div className="w-full md:w-2/3 p-4">
							<img
								src={section.image}
								alt={section.title}
								className="w-full h-auto rounded-lg shadow-lg cursor-pointer"
								onClick={() => openModal(section.image)}
							/>
						</div>
						<div className="w-full md:w-1/3 p-4">
							<h3 className="text-3xl font-semibold mb-4">{section.title}</h3>
							<p className="text-lg">{section.content}</p>
						</div>
					</div>
				))}
				{isOpen && (
					<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" onClick={closeModal}>
						<img src={selectedImage} alt="Expanded" className="max-w-[90%] max-h-[90%] rounded-lg" onClick={(e) => e.stopPropagation()} />
					</div>
				)}

				<div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-16 max-md:mx-2">
					<div className="p-4 bg-purple-100 dark:bg-purple-900 rounded-lg">
						<h3 className="text-3xl font-semibold mb-2 text-purple-600 dark:text-purple-300">Bring your own API Keys & LLMs</h3>
						<p className="text-lg text-gray-700 dark:text-gray-300">
							Protocraft works seamlessly with your existing LLM APIs, currently OpenAI, Anthropic, and OpenRouter. Bring your own local LLMs and
							connect them to Protocraft.
						</p>
					</div>
					<div className="p-4 bg-pink-100 dark:bg-pink-900 rounded-lg">
						<h3 className="text-3xl font-semibold mb-2 text-pink-600 dark:text-pink-300">Alternate Between AI Models</h3>
						<p className="text-lg text-gray-700 dark:text-gray-300">
							Carry your chat memory between models, and switch models easily. For example, start by asking GPT-4 for a detailed task list, then
							move on to Claude Sonnet 3.5 to architect a design, and then move over to a local LLM to write code.
						</p>
					</div>
					<div className="p-4 bg-red-100 dark:bg-red-900 rounded-lg">
						<h3 className="text-3xl font-semibold mb-2 text-red-600 dark:text-red-300">Use Templates for Common Prompts</h3>
						<p className="text-lg text-gray-700 dark:text-gray-300">Create templates for common prompts and use them to speed up your workflow.</p>
					</div>

					<div className="p-4 bg-purple-100 dark:bg-purple-900 rounded-lg">
						<h3 className="text-3xl font-semibold mb-2 text-purple-600 dark:text-purple-300">All Chats are Local</h3>
						<p className="text-lg text-gray-700 dark:text-gray-300">
							All chats are saved locally on your machine, not stored on our servers. You can back up your data via data export, and with your
							existing cloud backup service, like iCloud or Dropbox.
						</p>
					</div>
					<div className="p-4 bg-pink-100 dark:bg-pink-900 rounded-lg">
						<h3 className="text-3xl font-semibold mb-2 text-pink-600 dark:text-pink-300">Secure Configuration Storage</h3>
						<p className="text-lg text-gray-700 dark:text-gray-300">
							Your API Keys & LLM Configurations are encrypted and stored locally on your machine.
						</p>
					</div>
					<div className="p-4 bg-red-100 dark:bg-red-900 rounded-lg">
						<h3 className="text-3xl font-semibold mb-2 text-red-600 dark:text-red-300">Runs on Mac, Windows, & Linux</h3>
						<p className="text-lg text-gray-700 dark:text-gray-300">
							Protocraft runs on Mac, Windows, and Linux, for both ARM64 and x86_64 architectures.
						</p>
					</div>
				</div>

				<div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-16 max-md:mx-2 max-md:mb-8">
					<div className="text-center p-6 bg-purple-100 dark:bg-purple-900 rounded-lg shadow-lg">
						<h2 className="text-5xl font-bold mb-4 text-purple-600 dark:text-purple-300">Buy License for $39</h2>
						<p className="text-3xl mb-4 text-gray-700 dark:text-gray-300">Perpetual-use license & 1 year of updates</p>
						<Link to="/purchase">
							<button className="px-6 py-3 bg-purple-600 text-white text-lg font-semibold rounded-lg hover:bg-purple-700 transition duration-200">
								Purchase Now
							</button>
						</Link>
					</div>

					<div className="text-center p-6 bg-pink-100 dark:bg-pink-900 rounded-lg shadow-lg">
						<h2 className="text-5xl font-bold mb-4 text-pink-600 dark:text-pink-300">Download Protocraft</h2>
						<p className="text-3xl mb-4 text-gray-700 dark:text-gray-300">For Windows, Mac OS X, and Linux</p>
						<Link to="/downloads">
							<button className="px-6 py-3 bg-pink-500 text-white text-lg font-semibold rounded-lg hover:bg-pink-600 transition duration-200">
								View Downloads
							</button>
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default Home;
