import React from "react";
import ImageWithModal from "../../../common/ImageWithModal";
import fileBrowserImg from "../../../../images/docs/file-browser/file-browser.png";
import setRootButtonImg from "../../../../images/docs/file-browser/choose-root-button.png";
import rightClickRootImg from "../../../../images/docs/file-browser/right-click-choose-root.png";
import optionsImg from "../../../../images/docs/file-browser/options.png";

const FileBrowserOverview = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-6">File Browser Overview</h1>

			<p className="mb-4">
				The File Browser in Protocraft is a powerful tool that allows you to navigate, manage, and interact with your project files directly within the
				application. This feature enhances your workflow by providing easy access to your project structure and file contents.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Overview</h2>
			<p className="mb-4">You can toggle on / off the File Browser by clicking on the "File Browser" button at the top of the program.</p>
			<ImageWithModal src={fileBrowserImg} alt="File Browser" className="mb-4" />
			<p className="mb-4">
				The File Browser displays your project's directory structure in a tree-like format. You can expand and collapse folders, select files, and
				perform various actions on your project files and folders.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Key Features</h2>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Navigate through your project's directory structure</li>
				<li>Create new files and folders</li>
				<li>Open files in the editor</li>
				<li>Set the project root directory</li>
				<li>Delete files and folders</li>
				<li>Access additional options through the context menu</li>
			</ul>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Setting the Root Directory</h2>
			<p className="mb-4">
				Setting the root directory for your workspace is an important step in organizing your project. There are two main ways to set the root
				directory:
			</p>

			<h3 className="text-xl font-semibold mt-6 mb-3">1. Using the Root Button</h3>
			<p className="mb-4">View the "Root" buttons next to each folder by choosing the option to "Show Change Project Root".</p>
			<ImageWithModal src={optionsImg} alt="File Browser Options" className="mb-4" />
			<p className="mb-4">Each folder in the File Browser now has a "Root" button next to it. To set a folder as the project root:</p>
			<ImageWithModal src={setRootButtonImg} alt="Set Root Button" className="mb-4" />
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Locate the folder you want to set as the root</li>
				<li>Click the "Root" button next to the folder name</li>
				<li>The selected folder will now be treated as the project root</li>
			</ol>

			<h3 className="text-xl font-semibold mt-6 mb-3">2. Using the Context Menu</h3>
			<ImageWithModal src={rightClickRootImg} alt="Right-click Context Menu" className="mb-4" />
			<p className="mb-4">Alternatively, you can use the context menu to set the project root:</p>
			<ol className="list-decimal list-inside ml-4 mb-4">
				<li>Right-click on the folder you want to set as the root</li>
				<li>Select "Set as Project Root" from the context menu</li>
				<li>The selected folder will become the new project root</li>
			</ol>

			<p className="mb-4">
				After setting the new root, you'll notice that the File Browser updates to show the selected folder as the top-level directory.
			</p>

			<h2 className="text-2xl font-semibold mt-8 mb-4">Context Menu Options</h2>
			<ImageWithModal src={rightClickRootImg} alt="Right-click Context Menu" className="mb-4" />
			<p className="mb-4">The File Browser context menu helps you to manage your files and folders:</p>
			<ul className="list-disc list-inside ml-4 mb-4">
				<li>Open in File Explorer: Opens the selected folder in your system's file explorer</li>
				<li>New File: Creates a new file in the selected directory</li>
				<li>New Folder: Creates a new folder in the selected directory</li>
				<li>Set Project Root: Sets the selected folder as the project root</li>
				<li>
					Delete: Removes the selected file or folder and its contents. This will move files and folders to the trash bin used for your operating
					system.
				</li>
			</ul>

			<p className="mt-4">
				By leveraging the File Browser and its features, you can efficiently manage your project files, set up your workspace, and streamline your
				development process within Protocraft.
			</p>
		</div>
	);
};

export default FileBrowserOverview;
